<template>
  <span>
    <slot></slot>
    <!-- BTN EDITAR -->
    <v-tooltip top color="primary" v-if="hasEdit">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="{ ...actionsProps, ...attrs }"
          v-on="on"
          @click="$emit('edit')"
          ><v-icon>{{ editIcon }}</v-icon></v-btn
        >
      </template>
      <span>{{ `${labels?.edit} ${keyName}` }}</span>
    </v-tooltip>
    <!-- BTN ELIMINAR -->
    <v-tooltip top color="error" v-if="hasDelete">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          v-bind="{ ...actionsProps, ...attrs }"
          v-on="on"
          @click="$emit('delete')"
          :disabled="deleteDisabled"
          ><v-icon>{{ deleteIcon }}</v-icon></v-btn
        >
      </template>
      <span>{{ `${labels?.delete} ${keyName}` }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import { Labels } from "@/Mixins/PageMixinData.js";
export default {
  name: "TableActionElement",
  props: {
    actionsProps: {
      type: Object,
      default: () => {},
      description: "Propiedades de los botones de accion",
    },
    hasEdit: {
      type: Boolean,
      default: true,
      description: "Mostrar boton de editar",
    },
    editIcon: {
      type: String,
      default: "mdi-pencil-outline",
      description: "Icono del boton de editar",
    },
    hasDelete: {
      type: Boolean,
      default: true,
      description: "Mostrar boton de eliminar",
    },
    deleteIcon: {
      type: String,
      default: "mdi-delete-outline",
      description: "Icono del boton de eliminar",
    },
    deleteDisabled: {
      type: Boolean,
      default: false,
      description: "Llave de elemento desactivado",
    },
    keyName: {
      type: String,
      default: "",
      description: "Nombre del elemento",
    },
  },
  computed: {
    labels: () => Labels,
  },
};
</script>
